img[data-lazy] {
  display: none;
}
.text-underline{
  text-decoration: underline;
}
.cursor-pointer{
  cursor: pointer;
}

.visible--desktop{
  @include media-breakpoint-mobile(){
    display: none;
  }
}
.visible--mobile{
  @include media-breakpoint-desktop(){
    display: none;
  }
}
.d--none{
  display: none;
}
.d--block{
  display: block;
}

.full-bleed {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
}
.d--flex-between{
  display: flex;
  justify-content: space-between;
}
.mb--0{
  margin-bottom: 0;
}
.text--center{
  text-align: center;
}
.text--right{
  text-align: right;

}
.u-a-i-c{
  align-items: center;
}
.u-m-l-2{
  margin-left: $spacer/2;
}
.u-link-body{
  color: $body-color;
}
.u-bor-bot{
  border-bottom:1px solid $border-color;
}

.bg--primary {
  @include media-breakpoint-up(md) {
    background: $primary;
  }
}


.review_bloc_product_flex {
  @include media-breakpoint-down(md) {
    margin-bottom: 1.5rem;
    > div {
      width: 100%;
    }
  }
}

.wishlist_bloc {
  @include media-breakpoint-down(xs) {
    width: 100%;
    > .an_wishlist-container, .cdpcp-compare-btn {
      justify-content: center;
      text-align: center;
      margin: 0 0.5rem 1rem 0.5rem !important;
      border: 1px solid $gray-400;
      width: 50%;
      height: 50px;
    }
  }
}
@include media-breakpoint-down(xs) {
  .product-add-to-cart{
    width: 100%;
    .product-quantity{
      justify-content: center;
    }
  }
}