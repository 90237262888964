.block-categories {
  @extend .card;
  @extend .card-body;
  box-shadow:$card-box-shadow;
  a{
    color:$body-color;
  }
  .category-sub__item{
    display: block;
    width: 100%;
  }
  .category-sub__item--0{
    border-bottom: 1px solid $border-color;
    padding: $spacer*0.75 0;
      &:not(.category-sub__item--0) {
        &:not(.category-sub__item--1) {
        padding-left: $spacer/2;
      }
    }
  }
  .category-sub-menu {
    margin: 0.5rem 0;
    li {
      .navbar-toggler {
        cursor: pointer;
      }
      &:not(.category-sub__item--0) {
          a {
            position: relative;
            padding-left: 1.125rem;
            &::after {
              content: '';
              width: 0px;
              height: 0px;
              border-style: solid;
              border-width: 5px;
              border-color: $primary transparent transparent transparent;
              display: inline-block;
              vertical-align: middle;
              position: absolute;
              bottom: 0;
              left: 0;
              //right: 0;
              margin: auto;
              transform: rotate(-90deg);
              top: 0;
            }
          }
      }
      .navbar-toggler {
        //margin-top: $spacer/2;
      }
    }
  }
}



//block categories


.category-sub__item:not(.category-sub__item--0):not(.category-sub__item--1) {
  padding-left: $spacer;
}
.block-categories .collapse-icons .remove {
  display: none;
}
.block-categories .collapse-icons[aria-expanded=true] {
  &.remove{
  display: block;
  }
  &.add{
    display: none;
  }
}