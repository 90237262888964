//
// Color system
//
$white:         #fff;
$primary:       #762269;
$black:         #000;
$dark : $black;
$gray-200:      #e9ecef;
$gray-300 :     #f1f1f1;
$gray-400:      #ced4da;
$gray : #ccc;
$card-cap-bg : $white;
// Options
$enable-rounded:            false;
$enable-shadows:            false;


$container-max-width : 1200px;

//
// Links
//
// Style anchor elements.

$breadcrumb-bg: transparent;
$link-hover-decoration:     none;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:      'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$headings-font-family:        'Poppins',$font-family-sans-serif;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$headings-font-weight: 600;

$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.875;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.325;
$h5-font-size:                $font-size-base * 1.125;
$h6-font-size:                $font-size-base * 0.9375;

$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.188rem;
$display2-weight: 400;
$display3-size: 1.563rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;

$small-font-size:             $font-size-base * 0.875;


// Components
//
// Define common padding and border radius sizes and more.

$box-shadow:                  2px 2px 8px 0 rgba($black,.2);

// Navs

$nav-tabs-border-width:             2px;
$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-link-hover-border-color:  transparent transparent $nav-tabs-border-color;
$nav-tabs-link-active-color:        $primary;
$nav-tabs-link-active-border-color: transparent transparent $primary;
// Pagination

$pagination-border-color:           $gray-400;
$pagination-disabled-border-color:  $gray-400;
$pagination-hover-border-color:     $gray-400;

// Modals
$modal-lg:                          990px;

//tables
$table-bg : $white;

// buttons
$btn-padding-y: 0.5rem;
$btn-padding-x: 1rem;

// inputs
$input-height : auto;


//navs
$nav-link-disabled-color : $gray-400;


// modals
$modal-header-padding: 0.9rem 1rem;