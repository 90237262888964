.custombanners {
  &.displayHome {
    .push_blocks {
      .banner-item {
        &.html-over {
          .custom-html {
            top: 50%;
            transform: translateY(-50%);
            padding: 3rem 0;
            background: rgba(255,255,255,0.8);
            width: 60%;
            @media (max-width: 992px) {
              width: 80%;
            }
            p{
              a {
                @extend .btn;
                @extend .btn-primary;
              }
            }
          }
        }
      }
    }
    .reassurance_element {
      .banner-item {
        .custom-html {
          h4 {
            color: $primary;
          }
          p{
            @media (max-width: 992px) {
              max-width: 70%;
            }
            margin: auto;
          }
        }
      }
    }
  }
  &.displayCustomBanners1 {
    .reassurance_bloc {
      margin-bottom: $spacer;
      .banner-item {
        .custom-html {
          position: relative;
          padding-left: 2rem;
          &:after{
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 30px;
            width: 30px;
            background: url('./../img/check.svg') no-repeat;
            background-size: cover;
          }
          p {
            text-align: left;
            font-size: 0.75rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &.displayCustomBanners2 {
    .featured_element {
      .custom-html {
        a {
          margin-top: 1rem;
          @extend .btn;
          @extend .btn-light;
        }
      }
    }
  }
  &.displayNav1 {
    p {
      span{
        font-size: 1.5rem;
        line-height: 1;
      }
    }
  }
}


.page-index {
  main {
    overflow: hidden;
  }
}

.sw-pgn, .p-hover:hover .sw-pgn , .cb-wrapper .carousel.swiper-container-horizontal{
  width: 100%;
}