#checkout {
  background: $light;
  .l-footer {
    color: $body_color;
    padding-bottom: $spacer;
  }
}

.l-footer {
  color: $white;
  background: $light;
  .block_newsletter{
    padding-top: 1.5rem;
    color: $body_color;
    p {
      color: $body_color;
    }
  }
  .cms-page-link,
  .account-list a{
    color: $white;
    &:hover{
      //color: theme-color('primary');
      text-decoration: underline;
    }
  }
}
.footer-container{
  padding:  3rem 0;
  background: $primary;
  margin-top: $footer-container-margin-top;

}

.footer__title{
  color:$white;
  @extend .h4;
  margin-bottom: 1.5rem;
  &:visited,&:hover{
    color: $white;
  }
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  &--mobile{
    display: none;
    margin-bottom: 0;
    padding: $spacer/2 0;
    border-bottom: 1px solid $border-color;
    

    &:after{
      content:"\e313";
      font-family: 'Material Icons';
      font-feature-settings: 'liga' 1;
      position: absolute;
      right: $grid-gutter-width/2;
      transition: .3s transform ease-in-out;
      font-size: $font-size-base *1.5;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform:         rotate(180deg);
    }
  }
}

@include media-breakpoint-mobile {
  .footer__title{
    &--desktop{

      display: none;
    }
    &--mobile{
      display: block;
    }
  }
}
.footer__copyright{
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav{
  align-items: center;
}
.header-nav__nav2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top{
  display: flex;
  justify-content: space-between;
  position: static;
}
.displayFooterAfter {
  width: 100%;
.payment-icons {
  background-image: url("/prestashop/templates/template03/img/payment-icons.png");
  display: inline-block;
  height: 30px;
  width: 50px;
  margin-right: 5px;
  background-size: 100% auto;
  margin-right: 10px;
  color: transparent;
  &.icon-payment-paypal {
    background-position: 0px -1080px;
  }
  &.icon-payment-mastercard {
    background-position: 0px -870px;
  }
  &.icon-payment-visa {
    background-position: 0px -1320px;
  }
  &.icon-payment-mistercash {
    background-position: 0px -930px;
  }
  &.icon-payment-paysafecard {
    background-position: 0px -1110px;
  }
  &.icon-payment-maestro {
    background-position: 0px -810px;
  }
  &.icon-payment-belfius {
    background-position: 0px -1680px;
  }
  &.icon-payment-kbc {
    background-position: 0px -1710px;
  }
  &.icon-payment-inghomepay {
    background-position: 0 -1830px;
  }
  &.icon-payment-banktransfer {
    background-position: 0px -150px;
  }
  &.icon-payment-klarnapaylater {
    background-position: 0px -1860px;
  }
}
}

