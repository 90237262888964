body{
  background-color: $wrapper-bg;
}
a{
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
sup{
  top:-0.3em;
}
main {
  overflow: hidden;
}
.container-fluid {
  max-width: $container-max-width;
}

.l-wrapper{
  @if $enable-fluid-layout {
    @extend .container-fluid;
    max-width: $container-max-width;
  } @else {
    @extend .container;
    max-width: $container-max-width;
  }
  &--boxed{
    @extend .container;

  }

}

.breadcrumb {
  border-top: 1px solid $gray-400;
  padding-left: 0;
}

.left-column {
  .search_filters, .block-categories {
    border: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}

.page-wrapper {
  border: 0;
}
.page-wrapper,.card-block{
  @extend .card;
}
.page-header{
  text-align: center;
  margin: $spacer 0;
  background: $card-cap-bg;
  h1{
    margin-bottom: 0;
  }
}
.page-content,.card-block{
  @extend .card-body;
}
.card-block{
  box-shadow:$card-box-shadow;;
}
.page-footer{
  @extend .card-footer;
  background: $card-cap-bg;
  &:empty{
    display: none;
  }

}


.card-header {
  background: $card-cap-bg;
}
.left-column > div,
.right-column > div{
  margin-bottom: $spacer;
}
/* HEADER */

/* FOOTER */
.l-footer{
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
}

/* MISC */
ul {
  list-style: none;
  padding-left: 0;
}
h1,.h1{
  text-transform: $h1-text-transform;

}
.quick-view{
  display: inline-block;
  @extend .small;
  cursor:pointer;
  text-align: center;
}
.form-group.form-group_forgot-password{
  margin-top: -($form-group-margin-bottom/2);
}

//touch spin

.bootstrap-touchspin
{
  box-shadow: $bs-touchspin-boxshadow;
  border:$input-border-width solid $input-border-color;
  > input {
    max-width: 60px;
    border:none;
    box-shadow: none;
    text-align: center;
    font-weight: 700;
  }
  .btn {
    position: relative;
    z-index: 2;
    color:$input-color;
    background-color: $white;

  }
  .input-group-btn{
    display: flex;
    &:first-child{
      margin-right:-$input-border-width;
    }
    &:last-child{
      margin-left:-$input-border-width;
    }
  }
}

/* minimizing reflows and avoiding page jumps */

.rc {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  background: rgba(0,0,0,0.05);
  transition: background 150ms;
  &:after{
    @extend .spinner-border;
    content: "";
    border-color: $gray-300;
    position: absolute;
    top: calc(50% - #{($spinner-height/2)});
    left: calc(50% - #{($spinner-width/2)});
    z-index: 2;
    border-right-color: transparent;
  }
  &--lazyload:after{
    display: none;
  }
}

.rc > * {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rc > a {
  display: block;
}
.ratio2_1{
  padding-top: 50%;
}
.ratio1_2{
  padding-top: 200%;
}
.ratio4_3{
  padding-top: 75%;
}
.ratio16_9{
  padding-top: percentage(9/16);
}
.ratio1_1{
  padding-top: 100%;
}
.ratio3_2{
  padding-top: percentage(1-2/3);
}

/* collapse icons */
.icon-collapse{
  transition: transform 0.4s ease;


}
.icon-collapse[aria-expanded=true]{
  transform: rotateZ(180deg);
}

/* CHECKOUT STEP */
.order-checkout .card-body{
  padding-bottom: 0;

}
.step-edit[aria-expanded="true"]{
  display: none;
}
.step-title
{
  margin-bottom: 0;
}
.-complete.-reachable .step-title .done {
display: inline-block;
}
.-current .step-title .done {
  visibility: hidden;
}
.-current .card-header{
  background-color: $white;
}


.accordion {
  .card-body .card {
    border: 1px solid $card-border-color;
    transition: border 0.1s linear;

  }
  .card-body .card-header{
    text-align: center;
  }
  .selected .card{
    border-color: $success;
    border-width: 2px;
  }
}
  //Personnal info
  .nav-tabs-info{
    font-size: $font-size-base*1.1;
    .active{
      font-weight: bold;
    }
  }

  //Addresses

  .address-selector
  {
    .card{
      transition: border 0.1s linear;

    }
    .card-header,
    .card-body{
      cursor: pointer;

    }
  }
  .card-body_add-address{
    padding: $card-spacer-y $card-spacer-x;
    .material-icons{
      opacity: .8;
    }
  }
  // SHipping
.delivery-option{
  padding: $spacer;
  margin-bottom: $spacer;
  background-color: $gray-200;
}
.carrier-extra-content:not(:empty){
  margin-bottom: $spacer*2;
  &:empty{
    display: none;
  }
}
.carrier-name{
  font-weight: $font-weight-bold;
}
  .carrier-price{
    font-weight: bold;
    font-size: $font-size-lg;
    color: $primary;
  }
//order list final recap
.order-line:not(:last-child){
  margin-bottom: $spacer/2;
}
.order-confirmation-table{
  padding: $spacer;
  border: 3px solid $card-border-color;
}


//forms
custom-file-label.selected:after{
  content: "" !important;

}
.label{
  @extend label;
}
//nav tabs
.nav-tabs  {
  .nav-link{
  color:$gray-600;
  font-weight: 700;
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    border-bottom-width: 3px;
  }
}
.tab-content{
  padding-top: $spacer;
}
//products-section-title
.products-section-title{
  text-transform: $section-title-text-transform;
  text-align: $section-title-text-align;
  display: block;
  margin-bottom: $section-title-margin-bottom;
  @extend .h1;
  font-family: $section-title-font-family;
  color:$section-title-color;
  font-size:$section-title-size;
  font-weight:$section-title-font-weight;
}

//home content spacing
.page-home > *{
  margin-bottom: $spacer-y;
}

@include media-breakpoint-mobile() {
  .modal-footer{
    flex-direction: column;
    flex-direction:column-reverse;
    & > .btn{
      margin: 0;
      width : 100%
    }
    & .btn:not(:last-child){
      margin-top: $spacer/2;
    }

  }
}

.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
  .nav-link {
    white-space: nowrap;
  }
}

//forms
main > .notifications-container{
  @extend .l-wrapper;
}
.notifications-container{
  ul{
    margin-bottom: 0;
  }
}
.js-invalid-feedback-browser:empty{
  display: none;
}
.form-group{
  label,.label{
  small,
  .small{
    font-size: .75 * $font-size-base;
  }
  }
}
@include media-breakpoint-desktop{

.page-content,
.checkout-form,
.page-wrapper--order-confirmation{
  form{
    width: 75%;
    max-width: 450px;
    margin: 0 auto;
  }
}
.page-wrapper--order-detail{
  form{
    margin: unset;
  }
}
}
.form-footer{
  margin-top: $spacer;
}

// badge order history
.badge.bright{
  color:#fff;
}

.search_bar{
  position: relative;
  .search_btn {
    cursor: pointer;
  }
  .header__search {
    position: absolute;
    right: -15px;
    top: -6px;
    width: 300px;
    display: flex;
    align-items: center;
    .close_search{
      color: $white;
      cursor: pointer;
    }
  }
}

.btn {
  &.js-touchspin {
    line-height: 1.3;
  }
  &.default {
    &:focus{
      box-shadow: none;
      &:active {
        box-shadow: none;
      }
    }
  }
}

.js-cart-line-product-quantity {
  border-right: 1px solid $gray-200!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

#product {
  [id^="product-description-short"] {
    font-size: $font-size-base!important;
  }
}

[data-quantity-product="quantity"] {
  .quantity_wanted_listing{
    height: 2.6rem;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
  }
  max-width: 60px;
}

.quick-view .material-icons {
  font-size: 1.5rem;
  line-height: 1;
}


#header {
  .blockcart.cart-preview {
    .dropdown-menu {
      padding: 10px 15px;
      width: 360px;
      min-width: 260px;
      right: 0;
      left: -165px;
      box-shadow: 0 7px 22px -5px rgba(47,56,68,0.2);
      z-index: 9999;
    }
    .dropdown-toggle {
      background: none;
      border: none;
    }
    .dropdown-product-item {
      display: table;
      position: relative;
      width: 100%;
      padding: 9px 0;
      border-top: 1px solid $gray-400;
      &:first-child {
        border-top: 0;
      }
      >.dropdown-product-thumb {
        display: table-cell;
        vertical-align: top;
        width: 55px;
        overflow: hidden;
        >img {
          display: block;
          width: 100%;
        }
      }
      >.dropdown-product-remove {
        display: block;
        position: absolute;
        top: 9px;
        right: 0px;
        line-height: 1;
        cursor: pointer;
        i {
          color: $danger;
          font-size: 1rem;
        }
      }
      >.dropdown-product-info {
        padding-right: 40px;
        padding-left: 0px;
        width: calc(100% - 55px);
      }
      .dropdown-product-title {
        display: block;
        padding-top: 2px;
        transition: color .3s;
        color: $body_color;
        font-size: $font-size-base;
        &:hover {
          color: $primary;
        }
      }
      .dropdown-product-details {
        color: $primary;
      }
    }
    .blockcart {
      background: $gray-100;
      height: 3rem;
      padding: .75rem;
      margin-left: .9375rem;
      text-align: center;
      white-space: nowrap;
    }
    .toolbar-dropdown-group {
      display: table;
      width: 100%;
      padding: 9px 0;
      border-top: 1px solid $gray-200;
      border-bottom: 1px solid $gray-200;
      font-size: $font-size-base;
      div {
        padding-bottom: 2px;
      }
    }
    .text-right {
      text-align: right;
    }
    .btn {
      display: block;
    }
  }
}
.dropdown-product-item {
  >.dropdown-product-info {
    display: table-cell;
    vertical-align: top;
  }
}

// navs
.nav-tabs .nav-link {
  color: $nav-link-disabled-color;
  font-weight: 400;
}

.modal-header .close {
  font-weight: 300;
  font-size: $spacer * 2 ;
}

.custom-file-label {
  height: 2.3rem;
}